'use client';

import { useState } from 'react';
import LogoSvg from '@assets/svg/logo.svg';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useAuth } from '@/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import LanguageChanger from '@/components/Locale/LanguageChanger';
import { routerLink } from '@/constant/router-link.const';
import { APP_NAME } from '@/constant/config';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const { isAuth, getUser, logOut } = useAuth();
  const { t } = useTranslation();

  const HeaderNavs = [
    {
      value: t('header:link.aboutUs'),
      href: routerLink.home,
    },
    {
      value: t('header:link.termsOfUse'),
      href: routerLink.docs.termOfUse,
    },
    {
      value: t('header:link.support'),
      onClick: () => window.$chatwoot.toggle("open"),
    }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <header className="w-full sticky z-[9999] left-0 right-0 bg-[#121212]">
      <div className="max-w-[1200px] w-full mx-auto flex justify-between items-center px-4 md:px-9 h-[64px]">
        <Link href={routerLink.home} className="flex items-center gap-2">
          <LogoSvg width={32} height={32} className="bg-white p-[4px] rounded-[4px]" />
          <span className="text-white text-[20px] md:text-[24px] font-medium">{APP_NAME.toUpperCase().slice(1)}</span>
        </Link>
        <nav className="hidden md:flex p-1 gap-4 text-white text-[14px] font-normal mx-auto">
          {HeaderNavs.map(({ href, value, onClick }) => (
            <span key={value} onClick={onClick} className="cursor-pointer">
              {href ? <Link href={href}>{value}</Link> : value}
            </span>
          ))}
        </nav>
        <div className="hidden md:flex gap-4 text-white text-[14px] font-normal">
          {isAuth ? (
            <>
              <span className="text-white p-1 cursor-pointer" onClick={() => router.push(routerLink.partner)}>
                {getUser?.email}
              </span>
              <div className="border p-1 rounded-full hover:bg-white">
                <span className="text-white cursor-pointer p-3 hover:text-black" onClick={logOut}>
                  {t('header:button:logOut')}
                </span>
              </div>
            </>
          ) : (
            <>
              <span className="text-white p-1 cursor-pointer" onClick={() => router.push(routerLink.auth.signIn)}>
                {t('header:button.signIn')}
              </span>
              <div className="border p-1 rounded-full hover:bg-white">
                <span className="text-white cursor-pointer p-3 hover:text-black" onClick={() => router.push(routerLink.auth.signUp)}>
                  {t('header:button.signUp')}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="hidden md:flex items-center">
          <LanguageChanger />
        </div>
        <div className="md:hidden flex items-center">
          <LanguageChanger />
          <button className="text-white text-2xl ml-2" onClick={toggleMenu}>
            ☰
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out z-[9998] md:hidden`}
        onClick={closeMenu}
      >
        <div className="w-3/4 h-full bg-[#121212] p-4 flex flex-col justify-between" onClick={handleMenuClick}>
          <div>
            <div className="flex justify-between items-center w-full mb-4">
              <Link href={routerLink.home} className="flex items-center gap-2" onClick={closeMenu}>
                <LogoSvg width={32} height={32} className="bg-white p-[4px] rounded-[4px]" />
                <span className="text-white text-[20px] font-medium">{APP_NAME.toUpperCase().slice(1)}</span>
              </Link>
              <button className="text-white text-2xl" onClick={closeMenu}>
                ✖
              </button>
            </div>
            <div className="flex flex-col space-y-2">
              {HeaderNavs.map(({ href, value, onClick }) => (
                <span key={value} onClick={onClick} className="cursor-pointer text-white block w-full text-left text-[20px] py-2 border-b border-gray-700">
                  {href ? <Link href={href} onClick={closeMenu}>{value}</Link> : value}
                </span>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-4 mt-4 gap-4 p-5">
            {isAuth ? (
              <>
              <div className='flex flex-col gap-4 p-5 text-white text-center '>
                <span className="cursor-pointer" onClick={() => { router.push(routerLink.partner); closeMenu(); }}>
                  {getUser?.email}
                </span>
                <span className=" cursor-pointer border rounded-full px-4 py-2 hover:bg-white hover:text-black" onClick={logOut}>
                {t('header:button:logOut')}
                </span>
              </div>
              </>
            ) : (
              <>
              <div className='flex flex-col gap-4 p-5 text-white text-center '>
                <span className=" cursor-pointer border rounded-full px-4 py-2 hover:bg-white hover:text-black" onClick={() => { router.push(routerLink.auth.signIn); closeMenu(); }}>
                  {t('header:button.signIn')}
                </span>
                <span className="cursor-pointer border rounded-full px-4 py-2 hover:bg-white hover:text-black" onClick={() => { router.push(routerLink.auth.signUp); closeMenu(); }}>
                  {t('header:button.signUp')}
                </span>
              </div>
              </>
            )}
          </div>
        </div>
        <div className="w-1/4 h-full backdrop-blur-md bg-black bg-opacity-60" />
      </div>
    </header>
  );
};

export default Header;
