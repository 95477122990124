'use client'
import { routerLink } from '@/constant/router-link.const';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { APP_NAME, APP_URL, SUPPORT_MAIL } from '../../../constant/config';
import InstagramSvg from './svg/social/instagram.svg';
import XSvg from './svg/social/x.svg';
import TelegramSvg from './svg/social/telegram.svg';
import MediumSvg from './svg/social/medium.svg';
import LogoSvg from '@assets/svg/logo.svg';
import Okchanger from './svg/partner/okchanger.svg';
import KursExpert from './svg/partner/kurs_expert.svg';
import Wot from './svg/partner/wot.svg';
import SwapZone from './svg/partner/swapzone.svg';
import GlazOk from './svg/partner/glazok.svg';
import EobmenObmen from './svg/partner/eObmen.svg';
import Wellcrypto from './svg/partner/wellcrypto.svg';
import CryptoRu from './svg/partner/crypto_ru.svg';
import XRates from './svg/partner/rates.svg';
import Emon from './svg/partner/emon.svg';
import WebCheck from './svg/partner/webproverka.svg';
import Kurs24 from './svg/partner/24kurs.svg';
import BitsMedia from './svg/partner/BitsMedia.svg';
import BestChange from './svg/partner/bestchange.svg';
import KursoffBiz from './svg/partner/kursoff.svg';
import Scanbit from './svg/partner/scanbit.svg';


const style = {
  title: 'text-white font-bold text-xl w-full',
  text: 'hover:text-white text-[#909090] cursor-pointer'
}

const social = [
  { Component: TelegramSvg, key: 'telegram', href: 'https://t.me/Ebitok_Exchanger' },
  { Component: XSvg, key: 'x', href: 'https://x.com/EbitokNews' },
  { Component: InstagramSvg, key: 'instagram', href: 'https://www.instagram.com/ebitokexchanger' },
  { Component: MediumSvg, key: 'medium', href: 'https://medium.com/@ebitok' },
];

const partners = [
  { Component: Okchanger, key: 'okchanger', href: 'https://www.okchanger.ru/exchangers/Ebitok' },
  { Component: KursExpert, key: 'kurs.expert', href: 'https://kurs.expert/ru/obmennik/ebitok-com/feedbacks.html#reputation' },
  { Component: BitsMedia, key: 'bits.media', href: 'https://bits.media/exchanger/ebitok/' },
  { Component: Wot, key: 'mywot', href: 'https://www.mywot.com/scorecard/ebitok.com' },
  { Component: GlazOk, key: 'glazok', href: 'https://glazok.org/' },
  { Component: EobmenObmen, key: 'eobmen-obmen', href: 'https://eobmen-obmen.ru/' },
  { Component: Wellcrypto, key: 'wellcrypto', href: 'https://wellcrypto.io/' },
  { Component: XRates, key: 'xrates', href: 'https://xrates.ru/' },
  { Component: Emon, key: 'e-mon', href: 'https://e-mon.cc/' },
  { Component: WebCheck, key: 'webproverka', href: 'https://webproverka.com/' },
  { Component: Kurs24, key: 'Kurs24', href: 'https://24kurs.com/' },
  { Component: KursoffBiz, key: 'KursoffBiz', href: 'https://kursoff.biz/uk/exchange/Ebitok' },
  { Component: Scanbit, key: 'KursoffBiz', href: 'https://scanbit.com.ua/ua/' },
]

const Footer = () => {
  const { t } = useTranslation();
  const router = useRouter()

  return (
    <div className='border-t border-solid border-[#212123] sticky top-[100vh]'>
      <div className='flex flex-col gap-5 md:px-9 md:pt-20 p-5 container md:bg-black bg-[#242424]'>
        <div className='flex flex-wrap md:gap-24 gap-5 mt-5'>
          <div className='flex flex-wrap gap-2'>
            <div className='flex flex-col gap-2'>
              <Link href={routerLink.home} className="flex gap-2">
                <LogoSvg width={32} height={32} className="bg-white p-[4px] rounded-[4px]" />
                <span className="text-white text-[20px] font-medium">{APP_NAME.toUpperCase().slice(1)}</span>
              </Link>
              <span className='max-w-40 '>{t('footer:info.title')}</span>
              <div className='flex flex-wrap'>
                {social.map(({ Component, key, href }) => (
                  <Link key={key} href={href} aria-label={key} target='_blank' >
                    <Component key={key} className='w-[35px] h-[35px] fill-none stroke-[#909090] hover:stroke-white' />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <span className={style.title}>{t('footer:company.title')}</span>
            <div className='flex flex-col text-left gap-2'>
              <Link className={style.text} href={routerLink.docs.termOfUse}>{t('footer:company.links.termOfUse')}</Link>
              <Link className={style.text} href={routerLink.docs.privacyPolicy}>{t('footer:company.links.privacyPolicy')}</Link>
              <Link className={style.text} href={routerLink.docs.forCompetent}>{t('footer:company.links.forCompetentAuthorities')}</Link>
              <Link className={style.text} href={routerLink.docs.amlKyc}>{t('footer:company.links.amlKyc')}</Link>
              <Link className={style.text} href={routerLink.docs.termOfUse}>{t('footer:company.links.about')}</Link>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <span className={style.title}>{t('footer:contacts.title')}</span>
            <div className='flex flex-col text-left gap-2'>
              <Link className={style.text} href={`mailto: ${SUPPORT_MAIL}`} target='_blank' scroll={false}>{t('footer:contacts.links.email', { supportEmail: SUPPORT_MAIL })} </Link>
              <Link className={style.text} href='https://t.me/EbitokCustomerCare' target='_blank' scroll={false}>{t('footer:contacts.links.telegram')}</Link>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <span className={style.title}>{t('footer:forPartners.title')}</span>
            <div className='flex flex-col text-left gap-2'>
              <Link className={style.text} href={routerLink.docs.affiliateProgram}>{t('footer:forPartners.links.program')}</Link>
              <Link className={style.text} href={`https://docs.ebitok.com`} target="_blank">{t('partners:settings:privateApi:link')}</Link>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap md:gap-18 gap-10 mt-5'>
          <div className='flex flex-col gap-4'>
            <span className={style.title}>{t('footer:buy.title')}</span>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Bitcoin'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'Ethereum'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Monero'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Litecoin'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Cardano'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'Dogecoin'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Ripple'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'BNB'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-0 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Tron'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'Nexo'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Vechain'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'USDC'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'USDT (Tether)'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'Stellar'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Bitcoin cash'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'DigiByte'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Ravencoin'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'EOS'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'SHIB'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Polkadot'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left gap-1'>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Dash'})}</span>
              <span className={style.text} >{t('footer:buy.links.buy', { currency: 'UNI'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'Solana'})}</span>
              <span className={style.text}>{t('footer:buy.links.buy', { currency: 'altcoins'})}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap md:gap-10 gap-5 mt-5'>
          <div className='flex flex-col gap-4'>
            <span onClick={() => router.push(routerLink.home)} className={style.title}>{t('footer:exchangePair.title')}</span>
            <div className='flex flex-col text-left'>
              <span className={style.text}>{t('footer:exchanger.links.exchange', { currency: 'Bitcoin'})}</span>
              <span className={style.text}>{t('footer:exchanger.links.exchange', { currency: 'ETH'})}</span>
              <span className={style.text}>{t('footer:exchanger.links.exchange', { currency: 'altcoins'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left'>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'ETH', to: 'BTC'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'BTC', to: 'ETH'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'LTC', to: 'ETH'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left'>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'USDT', to: 'BTC'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'BTC', to: 'XRP'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'BTC', to: 'USDT'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left'>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'LTC', to: 'BTC'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'XRP', to: 'BTC'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'BCH', to: 'BTC'})}</span>
            </div>
          </div>
          <div className='flex flex-col gap-4 mt-11'>
            <div onClick={() => router.push(routerLink.home)} className='flex flex-col text-left'>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'ETH', to: 'USDT'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'DOGE', to: 'BTC'})}</span>
              <span className={style.text}>{t('footer:exchangePair.links.to', { from: 'BTC', to: 'DOGE'})}</span>
            </div>
          </div>
        </div>
        <div className='border border-solid border-[#212123] my-5'></div>
        <div className='flex flex-wrap gap-14 justify-center'>
          {partners.map(({ Component, key, href }) => (
            <Link key={key} href={href} aria-label={key} target='_blank' >
              <Component key={key} className='w-[100px] h-[30px] fill-[#909090] hover:fill-slate-50' />
            </Link>
          ))}
        </div>
        <span className='cursor-pointer text-center text-sm text-[#919191]'>© {`${APP_URL.toUpperCase()} ${new Date().getFullYear()}`}</span>
      </div>
    </div>
  );
};

export default Footer;
